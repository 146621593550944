<template>
  <div>
    <Filter />
    <DeviceTable />
  </div>
</template>
<script>
import Filter from './components/Filter.vue'
import DeviceTable from './components/DeviceTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  components: { Filter, DeviceTable },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      member: 'auth/member',
      token: 'auth/token',
      authToken: 'emnify/authToken',
      devices: 'devices/devices',
      sims: 'emnify/sims',
      endpoints: 'emnify/endpoints',
    }),
    ...mapFields('devices', ['filter']),
  },
  created() {
  },
  mounted() {
    let payload = {
      params: {
        ...this.filter,
      },
    }
    this.$store.dispatch('devices/getDevice', payload)
  },
  methods: {
    ...mapActions({
      addDevice: 'devices/addDeviceApi',
      getOrganisations: 'organisations/getOrganisations',
    }),
  },
}
</script>
