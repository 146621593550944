<template>
  <el-form :inline="true" class="demo-form-inline">
    <el-input
      style="width: 15%"
      class="m-2"
      @keyup.enter="handleFilter"
      placeholder="Rechercher"
      v-model="filter.q"
    >
    </el-input>

    <el-select
      class="w-10 m-2"
      v-model="filter.organizationId"
      placeholder="Organisation"
      clearable
      filterable
      remote
      :remote-method="getOrganizations"
      :loading="loading"
    >
      <el-option
        v-for="item in orgOptions"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      class="w-10 m-2"
      v-model="filter.retailerId"
      placeholder="Revendeur"
      clearable
      filterable
      remote
      :remote-method="getRetailers"
      :loading="loading"
    >
      <el-option
        v-for="item in retailerOptions"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-select
      class="w-1 m-2"
      v-model="filter.make"
      clearable
      style="width: 11%"
      placeholder="marque"
    >
      <el-option label="Teltonika" value="Teltonika" />
      <el-option label="Albatross" value="Albatross" />
      <el-option label="Gt06" value="Gt06" />
    </el-select>
    <el-select
      class="w-1 m-2"
      v-model="filter.model"
      clearable
      style="width: 11%"
      placeholder="modèle"
    >
      <el-option label="Vegeo" value="VEGEO" />
      <el-option label="Mini" value="Mini" />
      <el-option label="MinicAN" value="MinicAN" />
      <el-option label="Mongi" value="Mongi" />
      <el-option label="Mini GPS" value="Mini GPS" />
      <el-option label="Can" value="Can" />
      <el-option label="FM" value="FM" />
      <el-option label="FM23" value="FM23" />
      <el-option label="FMB130" value="FMB130" />
    </el-select>
    <el-select
      class="w-7 m-2"
      style="width: 7%"
      v-model="filter.status"
      clearable
      placeholder="connexion"
    >
      <el-option label="En ligne" value="online" />
      <el-option label="Hors ligne" value="offline" />
    </el-select>

    <el-button class="w-7 mb-1 ml-2" type="primary" @click="handleFilter" plain
      >Chercher
    </el-button>
  </el-form>
  <!-- </el-row> -->
</template>
<script>
import { ElMessage } from 'element-plus'
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { reactive, ref } from 'vue'
export default {
  data() {
    return {
      dialogTableVisible: ref(false),
      dialogFormVisible: ref(false),
      form: reactive({
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      }),
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      organizations: 'organisations/organisations',
      isAppUser: 'auth/isAppUser',
    }),
    ...mapFields('devices', ['filter', 'orgOptions', 'retailerOptions']),
    ...mapFields('devices', ['devicesFilter']),
  },
  methods: {
    handleCommand(command) {
      ElMessage(`click on item ${command}`)
    },
    handleFilter() {
      this.devicesFilter = true
      this.dialogFormVisible = false

      let payload = {
        params: {
          ...this.filter,
        },
      }
      this.$store.dispatch('devices/getDevice', payload)
    },
    getOrganizations(query) {
      if (query) {
        this.loading = true
        let payload = {
          params: {
            q: query,
          },
        }
        this.$store
          .dispatch('organisations/getOrganisations', payload)
          .then((data) => {
            this.loading = false
            this.$store.commit('devices/SET_ORG_OPTIONS', data)
          })
      }
    },
    getRetailers(query) {
      if (query) {
        this.loading = true
        let payload = {
          params: {
            q: query,
          },
        }
        this.$store.dispatch('retailers/getRetailers', payload).then((data) => {
          this.loading = false
          this.$store.commit('devices/SET_ORG_OPTIONS', data)
          // this.retailerOptions = data
        })
      }
    },
  },
}
</script>
<style>
</style>
